import * as React from 'react';
import Table from '@mui/material/Table';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Button from '@mui/material/Button';


export default function TableFiscal(props) {


    return (
        <>

            <Table className="pclp-table-investments"
                aria-labelledby="tableTitle"
                sx={{
                    'width': '100%',
                }}
            >

                <tbody>


                { Object.entries(props.rows).reverse().map(([key, value]) => {
                    return (
                        <tr key={key}>
                            <td>{key}</td>
                            <td>{value.message}</td>
                            <td>{value.id ?
                            <Button sx={{ my: 1 }} href={props.config.backend_api + 'getfile.php?s=d&id=' + value.id} target="_blank">
                                <PictureAsPdfIcon style={{ color: 'red' }} fontSize="small" sx={{ mr:1 }}/> Descargar
                            </Button>
                            : null}</td>
                        </tr>
                    );
                

                })
                }
                


                </tbody>
            </Table>
        </>
    );
}
