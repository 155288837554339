import * as React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";


import Container from "@mui/material/Container";
//import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from "@mui/material/Box";

import Resumen from './Resumen';
import TableEnCurso from './TableEnCurso';
import TableCerradas from './TableCerradas';
import TableFiscal from './TableFiscal';


import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';



function Dashboard(props) {
  const navigate = useNavigate();
  //const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [error, setError] = useState("");

  const [resumen, setResumen] = useState([]);
  const [rowsPendientes, setRowsPendientes] = useState([]);
  const [rowsRealizadas, setRowsRealizadas] = useState([]);
  const [rowsFiscal, setRowsFiscal] = useState([]);
  

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("loggedIn");
    if (!loggedIn) {
      navigate("/login");
    }
  }, [navigate]);


  const [activeTab, setActiveTab] = React.useState('1');

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };



  useEffect(() => {

    if (sessionStorage.getItem("loggedIn")) {
      //const userId = sessionStorage.getItem("userId");
      const userData = JSON.parse(sessionStorage.getItem("userData"));

      axios
        .post(props.config.backend_api + "getinvestordata.php", {
          userId: userData.userId,
        })
        .then((response) => {
          //console.log(response);
          if (response.data.status === "success") {
            setRowsPendientes(response.data.data.pendientes);
            setRowsRealizadas(response.data.data.realizadas);
            setResumen(response.data.data.resumen);
            //fillFiscal();
          }
          if (response.data.status === "error") {
            //window.location.href = "/login";
            //setApiResponse(response.data.message);
            setError("Ocurrió un error al cargar los datos.");
          }

        })
        .catch((error) => {
          console.error(error);
        });
    }

  }, [props.config.backend_api]);







  useEffect(() => {

    let distinctYears = {};
    //let tempo = 0;
    const currentYear = new Date().getFullYear();
    //const userData = JSON.parse(sessionStorage.getItem("userData"));
    rowsRealizadas.forEach((rowsRealizada, index) => {
      // Extract year from rowsRealizada.Fecha_desestimiento yyyy-mm-dd
      let anyo = rowsRealizada.Fecha_desestimiento.split('-')[0];

      // if year is in rowsFiscalLocal then do not call the API
      if ( rowsFiscal[anyo] || anyo === currentYear) {
        return;
      }

      setRowsFiscal(existingValues => ({
        // Cargar el objeto
        ...existingValues,
        // actualizar valor del campo especificado y cambia estado a touched
        [anyo]: { ...existingValues[anyo], id: '', message: 'Cargando información ... ' },
      }))

      
      // Y variable local distinctYears para no repetir peticiones
        if (!distinctYears[anyo]) {
          distinctYears[anyo] = anyo;
            cargarDatosAnyo(anyo);
        }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.config.backend_api, rowsRealizadas]);


  let loadingFiscal = false;

  function cargarDatosAnyo (anyo) {
    // SI loadingFiscal es true, esperar 10 segundos y volver a intentar
    if (loadingFiscal) {
      //console.log('CARGAR ANYO ' + anyo + ' ESPERANDO X SEGUNDOS');
      setTimeout(function () {
        cargarDatosAnyo(anyo);
      }, 3000);
      return;
    }
    //console.log('CARGAR ANYO ' + anyo + ' SIN ESPERAR');
    loadingFiscal = true;
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    axios
      .post(props.config.backend_api + "generatefiscalinversor.php", {
        userId: userData.userId,
        anyo: anyo
      })
      .then((response) => {
        if (response.data.status === 'success') {

          setRowsFiscal(existingValues => ({
            ...existingValues,
            [anyo]: { ...existingValues[anyo], anyo: anyo, id: response.data.data.id, message: response.data.message },
          }))

        } else {

          setRowsFiscal(existingValues => ({
            ...existingValues,
            [anyo]: { ...existingValues[anyo], anyo: anyo, id: null, message: 'No se ha podido cargar el archivo' },
          }))


        }
        loadingFiscal = false;
      })
      .catch((error) => {
        //console.log(error);

        setRowsFiscal(existingValues => ({
          ...existingValues,
          [anyo]: { ...existingValues[anyo], anyo: anyo, id: null, message: 'No se ha podido cargar el archivo' },
        }))

        loadingFiscal = false;
      });
  }



  return (
    <div>



      <Container disableGutters maxWidth={false} >



        {error && (
          <Alert style={{ marginBottom: "20px" }} severity="error">
            {error}
          </Alert>
        )}

        <Box sx={{ backgroundColor: '#000000', color: '#ffffff' }}>
          <Container disableGutters maxWidth='xl' >
            <TabContext value={activeTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{ backgroundColor: '#000000', color: '#ffffff' }}
                  textColor="inherit"
                  indicatorColor="#ffffff"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: '#ffffff',
                    }
                  }}
                  className="pclp-TabList"
                >
                  <Tab label="Resumen" value="1" />
                  <Tab label="Inversiones en curso" value="2" />
                  <Tab label="Inversiones cerradas" value="3" />
                  <Tab label="Información fiscal" value="4" />
                </TabList>
              </Box>

            </TabContext>
          </Container>

        </Box>


        <Container disableGutters maxWidth='xl' >
          <TabContext value={activeTab}>
            <TabPanel value="1" sx={{ p: 2 }}>
              <div className="pcpl-tab-header">
                <h1>Resumen</h1>
              </div>
              <Resumen data={resumen} />
            </TabPanel>
            <TabPanel value="2">
              <div className="pcpl-tab-header">
                <h1>Inversiones en curso</h1>
                <p>Los cálculos de beneficios, rentabilidad y fecha de cierre son estimados.</p>
              </div>
              <div className="pclp-containerTable">
                <TableEnCurso rows={rowsPendientes} {...props} />
              </div>
            </TabPanel>
            <TabPanel value="3">
              <div className="pcpl-tab-header">
                <h1>Inversiones cerradas</h1>
                <p>El capital y los intereses de las operaciones ha sido reembolsado. Los cálculos de beneficios y rentabilidad son definitivos.</p>
              </div>
              <div className="pclp-containerTable">
                <TableCerradas rows={rowsRealizadas} {...props} />
              </div>
            </TabPanel>
            <TabPanel value="4">
              <div className="pcpl-tab-header">
                <h1>Información fiscal</h1>
                <p>Descarga de documentos de información fiscal para la declaración de la renta.</p>
              </div>
              <div className="pclp-containerTable">
                <TableFiscal rows={rowsFiscal} {...props} />
              </div>
            </TabPanel>
          </TabContext>
        </Container>



      </Container>






    </div>
  );
}

export default Dashboard;
